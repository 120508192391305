import React from "react";
import { OwcInputCustomized } from "../../../components/shared/OwcInputCustomized";

export const ConfigurationBaselineInput = ({ style = {}, ...args }) => (
  <div className="owcInputBox" style={{ width: 313.5 + 8, ...style }}>
    <OwcInputCustomized
      dataTestid="text-field-configurationBaseline"
      dataModel="configurationBaseline"
      onBlurValidation={undefined}
      valueValidation={undefined}
      {...args}
    />
  </div>
);
