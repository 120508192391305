import { OwcTextarea } from "@one/react";
import React from "react";
import { useFormikContext } from "formik";
import DATA_MODEL_TABLE from "../../../utils/constants/dataModelTable";

export const ConfigurationDetailsTextArea = ({ style = {}, ...args }) => {
  const formik = useFormikContext();
  return (
    <div className="owcInputBox" style={{ width: 920, ...style }}>
      <OwcTextarea
        formik={formik}
        label={DATA_MODEL_TABLE.configurationDetails.value}
        name={DATA_MODEL_TABLE.configurationDetails.key}
        style={{ width: "100%" }}
        assistiveText="Will be propagated to repository"
        rows={8}
        maxLength={500}
        placeholder={DATA_MODEL_TABLE.configurationDetails.value}
        value={formik.values?.configurationDetails}
        onBlur={formik.handleBlur}
        onInputChange={formik.handleChange}
        onInputKeyDown={formik.handleChange}
        {...args}
      />
    </div>
  );
};
