const DATA_MODEL_TABLE = {
  equipmentNickName: {
    key: "equipmentNickName",
    value: "Nickname"
  },
  equipmentId: {
    key: "equipmentId",
    value: "Equipment ID"
  },
  createdAt: {
    key: "createdAt",
    value: "Creation Date"
  },
  updatedAt: {
    key: "updatedAt",
    value: "Last Edited Date"
  },
  materialNumber: {
    key: "materialNumber",
    value: "Material number"
  },
  serialNumber: {
    key: "serialNumber",
    value: "Serial number"
  },
  equipmentCategory: {
    key: "equipmentCategory",
    value: "Category"
  },
  manufacturer: {
    key: "manufacturer",
    value: "Manufacturer"
  },
  equipmentModel: {
    key: "equipmentModel",
    value: "Model"
  },
  variant: {
    key: "variant",
    value: "Variant"
  },
  instrumentGTIN: {
    key: "instrumentGTIN",
    value: "GTIN"
  },
  instrumentRUDI: {
    key: "instrumentRUDI",
    value: "RUDI"
  },
  inventoryNumber: {
    key: "inventoryNumber",
    value: "Inventory number"
  },
  belongingToGroup: {
    key: "belongingToGroup",
    value: "Group"
  },
  siteName: {
    key: "siteName",
    value: "Site"
  },
  buildingLocation: {
    key: "buildingLocation",
    value: "Building"
  },
  floor: {
    key: "floor",
    value: "Floor"
  },
  room: {
    key: "room",
    value: "Room"
  },
  functionalLocation: {
    key: "functionalLocation",
    value: "Functional location"
  },
  systemOwner: {
    key: "systemOwner",
    value: "System owner"
  },
  responsiblePerson: {
    key: "responsiblePerson",
    value: "Responsible person"
  },
  responsibleProxy: {
    key: "responsibleProxy",
    value: "Responsible proxy"
  },
  systemStatus: {
    key: "systemStatus",
    value: "System status"
  },
  gxpRelevant: {
    key: "gxpRelevant",
    value: "Intended use GxP relevant"
  },
  qualificationStatus: {
    key: "qualificationStatus",
    value: "Qualification status"
  },
  qualificationDocuments: {
    key: "qualificationDocuments",
    value: "Qualification documents"
  },
  softwareVersion: {
    key: "softwareVersion",
    value: "Software version"
  },
  configurationBaseline: {
    key: "configurationBaseline",
    value: "Configuration baseline"
  },
  comment: {
    key: "comment",
    value: "Comment"
  },
  sop: {
    key: "sop",
    value: "SOP"
  },
  installedTests: {
    key: "installedTests",
    value: "Installed tests"
  },
  dateOfLastMaintanance: {
    key: "dateOfLastMaintanance",
    value: "Date of executed maintenance"
  },
  dateOfNextMaintanance: {
    key: "dateOfNextMaintanance",
    value: "Next maintenance or calibration"
  },
  documentedMaintenanceDate: {
    key: "documentedMaintenanceDate",
    value: "Maintenance dates documented elsewhere?"
  },
  dateOfNextPeriodicReview: {
    key: "dateOfNextPeriodicReview",
    value: "Date of periodic review"
  },
  maintenanceIntervalInDays: {
    key: "maintenanceIntervalInDays",
    value: "Maintenance interval in days"
  },
  maintenancePlan: {
    key: "maintenancePlan",
    value: "Maintenance or calibration plan"
  },
  electronicRecord: {
    key: "electronicRecord",
    value: "Electronic records"
  },
  isBookable: {
    key: "isBookable",
    value: "Bookable"
  },
  csv: {
    key: "csv",
    value: "CSV relevant"
  },
  electronicSignatures: {
    key: "electronicSignatures",
    value: "Electronic signatures"
  },
  isVisualized: {
    key: "isVisualized",
    value: "Visualized"
  },
  cluster: {
    key: "cluster",
    value: "Cluster"
  },
  equipmentAdministrator: {
    key: "equipmentAdministrator",
    value: "Test equipment admin"
  },
  costCenter: {
    key: "costCenter",
    value: "Cost center"
  },
  location: {
    key: "location",
    value: "Location"
  },
  linkedInstance: {
    key: "linkedInstance",
    value: "Linked instance"
  },
  controlledEquipmentStatus: {
    key: "controlledEquipmentStatus",
    value: "Controlled equipment status"
  },
  trackPositionInCluster: {
    key: "trackPositionInCluster",
    value: "Position is relevant"
  },
  weightInkg: {
    key: "weightInkg",
    value: "Weight in kg"
  },
  weightInLbs: {
    key: "weightInLbs",
    value: "Weight in lbs"
  },
  heightInmm: {
    key: "heightInmm",
    value: "Height in mm"
  },
  heightInInch: {
    key: "heightInInch",
    value: "Height in inch"
  },
  lengthInmm: {
    key: "lengthInmm",
    value: "Length in mm"
  },
  lengthInInch: {
    key: "lengthInInch",
    value: "Length in inch"
  },
  depthInmm: {
    key: "depthInmm",
    value: "Depth in mm"
  },
  depthInInch: {
    key: "depthInInch",
    value: "Depth in inch"
  },
  clearanceLeftInmm: {
    key: "clearanceLeftInmm",
    value: "Clearance left in mm"
  },
  clearanceLeftInInch: {
    key: "clearanceLeftInInch",
    value: "Clearance left in inch"
  },
  clearanceRightInmm: {
    key: "clearanceRightInmm",
    value: "Clearance right in mm"
  },
  clearanceRightInInch: {
    key: "clearanceRightInInch",
    value: "Clearance right in inch"
  },
  clearanceHeightInmm: {
    key: "clearanceHeightInmm",
    value: "Clearance height in mm"
  },
  clearanceHeightInInch: {
    key: "clearanceHeightInInch",
    value: "Clearance height in inch"
  },
  clearanceToFrontInmm: {
    key: "clearanceToFrontInmm",
    value: "Clearance to front in mm"
  },
  clearanceToFrontInInch: {
    key: "clearanceToFrontInInch",
    value: "Clearance to front in inch"
  },
  clearanceBackwardsInmm: {
    key: "clearanceBackwardsInmm",
    value: "Clearance backwards in mm"
  },
  clearanceBackwardsInInch: {
    key: "clearanceBackwardsInInch",
    value: "Clearance backwards in inch"
  },
  weightInlbs: {
    key: "weightInlbs",
    value: "Weight in lbs"
  },
  heightIninch: {
    key: "heightIninch",
    value: "Height in inch"
  },
  lengthIninch: {
    key: "lengthIninch",
    value: "Length in inch"
  },
  depthIninch: {
    key: "depthIninch",
    value: "Depth in inch"
  },
  clearanceLeftIninch: {
    key: "clearanceLeftIninch",
    value: "Clearance left in inch"
  },
  clearanceRightIninch: {
    key: "clearanceRightIninch",
    value: "Clearance right in inch"
  },
  clearanceHeightIninch: {
    key: "clearanceHeightIninch",
    value: "Clearance height in inch"
  },
  clearanceToFrontIninch: {
    key: "clearanceToFrontIninch",
    value: "Clearance to front in inch"
  },
  clearanceBackwardsIninch: {
    key: "clearanceBackwardsIninch",
    value: "Clearance backwards in inch"
  },
  editReason: {
    key: "editReason",
    value: "Select reason"
  },
  editComment: {
    key: "editComment",
    value: "Add comment"
  },
  tags: {
    key: "tags",
    value: "Tags"
  },
  displayImage: {
    key: "displayImage",
    value: "Display image"
  },
  isActive: {
    key: "isActive",
    value: "Equipment master data active?"
  },
  heatOutputInW: {
    key: "heatOutputInW",
    value: "Heat output in W"
  },
  pressureEquipment: {
    key: "pressureEquipment",
    value: "Pressure equipment"
  },
  noiseIndBA: {
    key: "noiseIndBA",
    value: "Noise in dBA"
  },
  noiseSource: {
    key: "noiseSource",
    value: "Noise source"
  },
  noiseSourceDetails: {
    key: "noiseSourceDetails",
    value: "Noise source details"
  },
  positioningNote: {
    key: "positioningNote",
    value: "Positioning note"
  },
  positioning: {
    key: "positioning",
    value: "Positioning"
  },
  vibrationSensitive: {
    key: "vibrationSensitive",
    value: "Vibration sensitive"
  },
  status: {
    key: "status",
    value: "Equipment status"
  },
  network: {
    key: "network",
    value: "Network"
  },
  checkInRequired: {
    key: "checkInRequired",
    value: "Requires check in"
  },
  checkInStartPeriod: {
    key: "checkInStartPeriod",
    value: "Check in period"
  },
  checkInGracePeriod: {
    key: "checkInGracePeriod",
    value: "Grace period"
  },
  configurationDetails: {
    key: "configurationDetails",
    value: "Configuration details"
  }
};
export default DATA_MODEL_TABLE;
